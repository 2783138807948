import React,{ Component } from 'react';

const InstallerContext = React.createContext();

class InstallerProvider extends Component {
    state = {
        installerList : [],
        productNamingMapping : {
            'solar+battery' : 'Solar + Battery',
            'solar': 'Solar',
            'battery': 'Battery',
            'evCharger': 'EV Charger',
            'hpwh': 'Water Heater',
            'hems': 'HEMS'
        },
        productsAvailable : [
            {name : 'solar+battery',checked : false},
            {name : 'solar',checked : false},
            {name : 'battery',checked : false},
            {name : 'hpwh',checked : false},
            {name : 'evCharger',checked : false}
        ],
        stateCountyData: [],
        selectedState : '',
        selectedCounty:'',
        selectedChannelPartner: '',
        approvalSystemType : 1,
        approvalList: [],
        installerCompanyData:{
          selectedInstallerId :'',
          installerName:'',
          organizationName:''

        }
        
    }
    setInstallerList = (data) => {
      this.setState({installerList : data})
    }
    setproductAvailable = (data) => {
    this.setState({productsAvailable : data})
    }
    setStateCountyData = (data) => {
        this.setState({stateCountyData : data})
    }
    setSelectedState = (data) => {
        this.setState({selectedState : data})

    }
    setSelectedCounty = (data) => {
        this.setState({selectedCounty : data})

    }
    setSelectedChannelPartner = (data) => {
        this.setState({selectedChannelPartner : data})

    }
    setApprovalList = (data) => {
        this.setState({approvalList : data})
    }
    setApprovalSystemType = (status) => {
        this.setState({approvalSystemType : status})
    }
    setInstallerCompanyData = (data)=>{
        this.setState({ installerCompanyData: {installerName : data.installer_users[0]?.name, selectedProjectId : data.id, 
            organizationName : data.installer_info?.installer_basic_info?.company_overview?.company_name ? data.installer_info?.installer_basic_info?.company_overview?.company_name:'NA'}})
    }
    render(){
        return(
            <InstallerContext.Provider value={{
                state: this.state,
                setInstallerList: this.setInstallerList,
                setproductAvailable: this.setproductAvailable,
                setStateCountyData: this.setStateCountyData,
                setSelectedState: this.setSelectedState,
                setSelectedCounty:this.setSelectedCounty,
                setSelectedChannelPartner:this.setSelectedChannelPartner,
                setApprovalList: this.setApprovalList,
                setapprovalSystemType: this.setApprovalSystemType,
                setInstallerCompanyData:this.setInstallerCompanyData,
            }}>
                {this.props.children}
            </InstallerContext.Provider>
        )
    }
}


export {InstallerContext, InstallerProvider}