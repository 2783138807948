import React, { useEffect, useState , useCallback} from "react";
import { ToastContainer } from "react-toastify";
import { ScrollToTop } from "../layout";
import { SSO_ORIGIN } from "../services/CONSTANTS";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "../layout/UI/dialog";
import { establishCommunicationToSSO, debounce } from "../utils/helper";
import { useDispatch } from "react-redux";
import { setSession, logout } from "../store/actions";
import { setModulePermissions, setPermissions } from "../config";
import { loadDefault, setModules } from "../store/actions";
import { getPermissions, getModulePermisions } from "../services";
import {useHistory, useLocation} from 'react-router-dom'

const Layout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {pathname} = useLocation();
  const events = [
    "mouseup", 
    "keydown", 
    "scroll", 
    "mousemove"
  ];

  const [confirmPopup, setConfirmPopup] = useState({
    isVisible: false,
    data: 0,
  });
  const [seconds, setSeconds] = useState(120);
  const [render, setRender] = useState(false)

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.origin !== SSO_ORIGIN) {
        return;
      }
      actionHandler(event.data);
    });
    window.addEventListener('DOMContentLoaded', function(){
      events.forEach(function (e) {
        document.getElementById('control-center').addEventListener(e, function(){
          userActivityTracker();
        })
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userActivityTracker = useCallback(debounce(()=>{
    establishCommunicationToSSO({
      event: "updateActivityTime",
    });
  }
  , 3000), []);

  useEffect(() => {
    let timer ;
    if (confirmPopup.isVisible) {
      let time = seconds;
      timer = setInterval(() => {
        console.log(time)
        if(time <= 1 ) {
          clearInterval(timer);
          sessionStorage.clear();
          window.location.reload();
        }
        time = time -1;
        setSeconds(time);
      }, 1000);
    }else{
      clearInterval(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPopup.isVisible]);

  const actionHandler = (data) => {
    if (data.event === "jwtinfo") {
      if (data && data.record) {
        localStorage.setItem("token", JSON.stringify(data.token));
        getRBACData().then((response) => {
          dispatch(setSession({ data: data, isLoggedIn: true }));
          console.log(pathname);
          history.push(pathname);
        });
      } else {
        localStorage.clear();
        dispatch(logout());
        history.replace('/');
      }
      setConfirmPopup({
        ...confirmPopup,
        isVisible: false,
      });
      // setRender(!render);
    }
    if (data.event === "expiry_notification") {
      const toTime = new Date(data.expiry_time);
      const difference = toTime.getTime() - new Date().getTime();
      const timeout = Math.floor(difference / 1000);
      // console.log(timeout, difference, toTime);
      setSeconds(timeout);
      setRender(!render);
      setConfirmPopup({
        ...confirmPopup,
        isVisible: true,
      });
    }
  };

  const getRBACData = () => {
    return new Promise((resolve) => {
      getModulePermisions().then((response) => {
        if (response.data.success) {
          const list = [];
          response.data.data.forEach((item) => {
            list.push(item.module_slug);
          });
          setModulePermissions(list);
          dispatch(setModules(list));
        } else {
          setModulePermissions([]);
        }
      });
      getPermissions().then((response) => {
        if (response.data.success) {
          setPermissions(response.data.data.permissions_list);
          dispatch(loadDefault());
          resolve(true);
        } else {
          setPermissions([]);
        }
      });
    });
  };

  const refreshTokenhandler = () => {
    establishCommunicationToSSO({
      event: "refreshToken",
    });
    setConfirmPopup({
      ...setConfirmPopup,
      isVisible: !confirmPopup.isVisible,
    });
  };

  const logoutSession = () => {
    establishCommunicationToSSO({
      event: "logout",
    });
  };
  return (
    <>
      <iframe
        id="ssoFrame"
        style={{ display: "none" }}
        src={SSO_ORIGIN}
        title="SSO"
      ></iframe>
      <ScrollToTop />
      <div id="control-center">
        {props.children}
      </div>
      <ToastContainer />
      {confirmPopup.isVisible && (
        <Backdrop
          sx={{
            color: "#fff",
            pointerEvent: "none",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={confirmPopup.isVisible}
        >
          <Dialog
            static
            show={confirmPopup.isVisible}
            crossMark={true}
            handleClose={() => {
              logoutSession();
              setConfirmPopup({
                ...confirmPopup,
                isVisible: !confirmPopup.isVisible,
              });
            }}
            title="Are you still there?"
            buttonText={["Log Out", "Stay Logged In"]}
            saveChanges={refreshTokenhandler}
          >
            Your session will expire in {seconds} seconds. Do you wish to remain
            logged in?
          </Dialog>
        </Backdrop>
      )}
    </>
  );
};

export default Layout;
