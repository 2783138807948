import axiosInstance from '../../../utils/interceptors/AxiosInstance';
import {ENGINE_URL, HUBBLE_ENGINE_URL } from '../../CONSTANTS';

export const getProjectListConfig = async(page = 1, limit = 15,search) => {
    return axiosInstance.post(ENGINE_URL + `control-center/projects-list?page=${page}&limit=${limit}`,search); 
}

export const getProjectFilesList = async(projectID) => {
    return axiosInstance.post(ENGINE_URL + `control-center/get-project-files`, projectID); 
}

export const deleteFilesList = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/delete-project-files`, data)
}
export const Approvelist = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/v2/review-design-file`, data)
}
export const uploadFile = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/upload-project-files`, data)
}
export const uploadFileMulti = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/update-multi-project-file`, data)
}

export const updateProjectFiles = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/update-project-file`, data)
}

export const updateGdriveLink = async(data) => {
    return axiosInstance.post(ENGINE_URL + `control-center/project-gdrive-sync-status`, data)
}
export const getprojectListFilters = async() => {
    return axiosInstance.get(ENGINE_URL + `control-center/project-list-filters`)
}

export const getprojectFileListFilters = async() => {
    return axiosInstance.get(ENGINE_URL + `control-center/project-files-list-filters`)
}
export const getprojectDetails = async(id) => {
    return axiosInstance.get(HUBBLE_ENGINE_URL + `project/getProjectDetails/${id}`)
}
export const getprojectDetailsNotes = async(id) => {
    return axiosInstance.get(HUBBLE_ENGINE_URL + `project/getProjectNotes/${id}`)
}

export const updateProjectNotes = async(data) => {
    return axiosInstance.post(HUBBLE_ENGINE_URL + `project/upsertProjectNotes`, data)
}
export const getprojectOptionValue = async() => {
    return axiosInstance.get(HUBBLE_ENGINE_URL + `project/getProjectOptionValues`)
}

export const UpdateProjectDetails = async(data) => {
    return axiosInstance.post(HUBBLE_ENGINE_URL + `project/updateProjectDetails`, data)
}