
/**
 * Here you can export functions with corresponding services / endpoints.
 * This is to keep track of all the services as a list.
 */

import axiosInstance from '../utils/interceptors/AxiosInstance';
 
 export const getPosts = async(prevState,page=1,order="asc",limit="10") => {
     try {
         const response = await axiosInstance.get(`https://jsonplaceholder7.typicode.com/todos/2`);
 
         return {
             posts: prevState.posts ? [...prevState.posts,...response.data] : response.data,
             page: page,
             end: response.data.length === 0 ? true : false
         }
     } catch(error){
         throw error;
     }
 }

 export const loadState = () =>{
    try {
        let serializedState = localStorage.getItem("userData");

        if (serializedState === null) {
            return {data : {}, isLoggedIn : false }
        }
        return { data : JSON.parse(serializedState), isLoggedIn : true};
    }
    catch (err) {
        return {data : {}, isLoggedIn : false }
    }
 }

 export const initializeState = () =>{
    return {
        type : 'set_user',
        payload : { 
            isLoggedIn: false,
            data : {}
        }
    };
}

export { userAuth, userLogout, doLogin, refreshToken } from "./account/accountService";
export {
  getChannelPartner,
  getInstallerList,
  getPermissions,
  getModulePermisions,
} from "./dashboard/dashboardService";
export {
  getProductList,
  getProductCategoryModelList,
  getAllCategery,
  getModelByCategoryId,
  getManufacturerBySolarId,
  getManufacturerByBattaryId,
  getSingleModelUIById,
  getSingleModelById,
  updateModelJSON,
  getModelJSONDefinition,
  getManufacturerById,
  addProductModel,
  upload_assets,
  upload_assets_Manufacturer,
  getManufacturerList,
  getSingleManufacturerDetail,
  getAllManufacturerList,
  deleteManufacturer,
  getCountryList,
  updateManufacturer,createManufacturer, restoreManufacturer
} from "./dashboard/product/productService";
export {
  getUserList,
  getAllRoles,
  getUserRoles,
  getSingleUserRole,
  getSingleUserPermissions,
  deleteRole,
  getAllPermissions,
  assignUserRole,
  updateRolesPermissions,
  createNewRole,
  deleteUser,
  getUserRoleList,
  userHistoryLog,
  getfilteruser_log
} from "./user/userService";
export {
  scheduleMaintenance,
  uploadDowntimeFile,
  getConfigs,
} from "./dashboard/maintenance/maintenanceService";
export {
  getInstallerListData,
  installerOnBoard,
  getServiceAreaByInstaller,
  getStateCountyData,
  getInstallerOrgList,
  installerApprovalHandler,
  sentVerificationEmailHandler,
  impersonationHandler,
  getInstallerCount,
  getAllInstallerForLogs,
  getInstallerHistoryLogs,
  reviveinstaller,
  getHistoryFilters,
  installerCompanyFileList,
  installermodule,
  deleteInstallerAdditionalUsers,
  getInstallerChannelPartnerConfig,
  getChannelPartnerList,
  getInstallerDataList,
  getInstallerDataByID,
  getInstallerDatasave,
  getChannelPartnerDatasave,
  copyInstallerConfig,
  getInstallerAdditionallist,
  copyChannelPartnerConfig,
  updateInstallerFlags
} from "./dashboard/installer/installerListService";
export {
  getStaffConfigData,
  getGlobalSettingsEventTypes,
  handleStaffProfileFlags,
  updateGlobalSettingsEventTypes,
  deleteStaffProfile,
  addRegion,
  getRegion,
  deleteRegion,
  getStateRegionGroupping,
  getAvailability,
  getProfileInfoByIDConfig,
  getAvailabilityListGroupDetail,
  alertTriggerUpdate,
  getStaffFilterData,
  updateStateRegionGroupping,
  getAvailabilityFilter,
  getAnalysis,
  getQuickSightDashboard,
  getStaffRoles,
  getReportingManagerDetails,
  setProfileInfoConfig,
  setUploadPhotoConfig,
  getStaffConfigLogs,
  getStaffBulkEventType,
  setStaffBulkEventType,
  getStaffConfigList,
  getStaffUsersConfigs,
  getStaffbulkUpdateConfigs,
} from "./dashboard/scheduler/staffConfigurationService";
export {
  deleteFilesList,
  uploadFile,uploadFileMulti,
  getProjectFilesList,
  getProjectListConfig,
  updateProjectFiles,
  updateGdriveLink,
  getprojectListFilters,
  getprojectFileListFilters,
  getprojectDetails,
  getprojectDetailsNotes,
  updateProjectNotes
  ,getprojectOptionValue,
  UpdateProjectDetails,
  Approvelist
} from "./dashboard/tools/projectListService";
export {
  getErrorList,
  retryJob,
  errorSummaryRecords,
} from "./dashboard/tools/errorLogsService";
export {
  templateList,
  DeletetemplateList,
  getdocumentsdeta,
  UPDATEeditor

} from "./dashboard/contracts/contractService"
