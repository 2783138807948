import React,{ Component } from 'react';

const DashboardContext = React.createContext();

class DashboardProvider extends Component {
    state = {
        roleList : [],
        activeRoute : '',
        activeRouteTitle : '',
        projectList : {
            selectedProjectId: '',
            list: '',
            searchText: ''
        },
        activeBreadcrumb : ''  
    }
    prepareRoleList = (user_roles) => {
        this.setState({roleList : user_roles});
    }
    checkAuth = (user = []) => {
      let count = user.filter((role) => this.state.roleList.indexOf(role) !== -1)
      return count.length > 0;
    }
    setActiveRoute = (route) => {
        this.setState({...this.state, activeRoute : route});
    }
    setActiveRouteTitle = (route) => {
        this.setState({...this.state, activeRouteTitle : route});
    }
    setProjectListData = (data) => {
        this.setState({ projectList: {list : data, selectedProjectId : data.id}})
    }
    setActiveBreadcrumb = (data) => {
        this.setState({activeBreadcrumb : data})
    }
    setSearchText = (data) => {
        this.setState({projectList: {...this.state.projectList, searchText: data}})
    }
    render(){
        return(
            <DashboardContext.Provider value={{
                state: this.state,
                prepareRoleList: this.prepareRoleList, 
                checkAuth : this.checkAuth,
                setActiveRoute: this.setActiveRoute,
                setProjectListData: this.setProjectListData,
                setActiveBreadcrumb: this.setActiveBreadcrumb,
                setSearchText: this.setSearchText,
                setActiveRouteTitle: this.setActiveRouteTitle
            }}>
                {this.props.children}
            </DashboardContext.Provider>
        )
    }
}


export {DashboardContext, DashboardProvider}