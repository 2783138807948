import React, { useState, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { DASHBOARD } from "./CONSTANTS";

//components
import SideMenu from "../layout/components/sidebar";
import Breadcrumb from "../layout/components/Breadcrumb";

//layout HOC
import MaintenanceLayout from "../hoc/MaintenanceLayout";
import SchedulerLayout from "../hoc/schedulerLayout";
import InstallerLayout from "../hoc/installerLayout";
import UserLayout from "../hoc/userLayout";
import ToolsLayout from "../hoc/toolsLayout";
import ProductLayoout from "../hoc/productLayout";

import HomeDashboard from "../pages/dashboard/homeDashboard";
import Login from "../pages/account/Login";
import DashboardPage from "../pages/dashboard/homeDashboard";
import {
  PrivateRouteWithSidebar,
  PrivateRouteWithoutSidebar
} from "./Auth/PrivateRoute";
import { useAuth } from "./Auth/ProvideAuth";

const InstallerList = lazy(() =>
  import("../pages/dashboard/installers/installerList")
);
const InstallerEdit = lazy(() =>
  import("../pages/dashboard/installers/installerList/editInstaller")
);
const ApprovalSystem = lazy(() =>
  import("../pages/dashboard/installers/approvalSystem")
);
const InstallerFileList = lazy(() =>
  import("../pages/dashboard/installers/approvalSystem/components/fileList")
);
const HpwhServiceArea = lazy(() =>
  import("../pages/dashboard/installers/hpwhServiceArea")
);
const ServiceArea = lazy(() =>
  import("../pages/dashboard/installers/installerServiceArea")
);
const HistoryLogs = lazy(() =>
  import("../pages/dashboard/installers/historyLogs")
);
const TemplateMapping = lazy(() =>
  import("../pages/dashboard/products/makerMapping")
);
const MakerMapping = lazy(() =>
  import("../pages/dashboard/products/makerMapping")
);
const CategoryModel = lazy(() =>
  import("../pages/dashboard/products/categoryModel")
);
const ProductMapping = lazy(() =>
  import("../pages/dashboard/products/productMapping")
);
const JSONEditor = lazy(() =>
  import("../pages/dashboard/products/productEditor/components/editor")
);
const AddProduct = lazy(() =>
  import("../pages/dashboard/products/productEditor/components/addProduct")
);
const EquipmentUI = lazy(() =>
  import("../pages/dashboard/products/equipmentUI")
);
const ManufacturerList = lazy(() => import("../pages/dashboard/products/manufacturer/index"));
const ManufacturerCreate = lazy(
  () => import("../pages/dashboard/products/manufacturer/component/addManufacturer")
);
const ManufacturerEdit = lazy(
  () => import("../pages/dashboard/products/manufacturer/component/editManufacturer")
);
const EquipmentUIEditSolar = lazy(() =>
  import("../pages/dashboard/products/equipmentUI/component")
);

const LDHistory = lazy(() => import("../pages/dashboard/tools/LDHistory"));
const ProductEditor = lazy(() =>
  import("../pages/dashboard/products/productEditor")
);
const ProximityZips = lazy(() =>
  import("../pages/dashboard/tools/proximityZips")
);
const TrustValueVault = lazy(() =>
  import("../pages/dashboard/tools/trustValueVault")
);
const ProductConfigurator = lazy(() =>
  import("../pages/dashboard/tools/productConfigurator")
);
const ErrorSummary = lazy(() =>
  import("../pages/dashboard/tools/errorLog/errorSummary")
);
const ErrorLog = lazy(() =>
  import("../pages/dashboard/tools/errorLog/errorList")
);
const FileUploader = lazy(() =>
  import("../pages/dashboard/tools/fileUploader")
);
const UserList = lazy(() => import("../pages/dashboard/users"));
const DowntimeConfigurator = lazy(() =>
  import("../pages/dashboard/maintenance/configurator")
);
const DowntimeReview = lazy(() =>
  import("../pages/dashboard/maintenance/downtimeReview")
);
const Maintenance = lazy(() =>
  import("../pages/dashboard/maintenance/maintenance")
);
const TypeSelection = lazy(() =>
  import("../pages/dashboard/maintenance/shared/downtimeTypeSelection")
);
const PartnerUtilityApiConfigurator = lazy(() =>
  import("../pages/dashboard/tools/partnerUtilityAPI")
);
const ProjectList = lazy(() => import("../pages/dashboard/tools/projectList"));
const ProjectListNew = lazy(() => import("../pages/dashboard/tools/projectListNew"));
const ProjectDetail = lazy(() => import("../pages/dashboard/tools/projectList/projectDetail"));
const ProjectFilesList = lazy(() =>
  import("../pages/dashboard/tools/projectList/component/fileList")
);
const Installer = lazy(() => import("../pages/dashboard/installers"));
const StaffConfigurator = lazy(() =>
  import("../pages/dashboard/scheduler/staffConfiguration")
);
const GlobalSettings = lazy(() =>
  import("../pages/dashboard/scheduler/globalSettings")
);
const StaffBulkEdit = lazy(() =>
  import("../pages/dashboard/scheduler/staffBulkEdit")
);
const Analysis = lazy(() => import("../pages/dashboard/scheduler/analysis"));
const UserConfigurator = lazy(() =>
  import("../pages/dashboard/scheduler/userConfiguration")
);
const EditEvent = lazy(() =>
  import("../pages/dashboard/scheduler/staffBulkEdit/components/editEvent")
);
const EditChannerPartner = lazy(() =>
  import(
    "../pages/dashboard/scheduler/staffBulkEdit/components/editChannelPartner"
  )
);
const EditRegion = lazy(() =>
  import("../pages/dashboard/scheduler/staffBulkEdit/components/editRegion")
);

const ChannelPartnerMapping = lazy(() => import("../pages/dashboard/tools/channelPartnerMapping"))
const EditChannelPartnerMapping = lazy(() => import("../pages/dashboard/tools/channelPartnerMapping/channelPartnerConfig"));
const UserHistoryLogs = lazy(() => import("../pages/dashboard/users/historyLogs"));
const TemplateList = lazy(() => import("../pages/dashboard/contracts/templateList"));
const TemplateEditor = lazy(() => import("../pages/dashboard/contracts/templateEditor"));
const Createtemplate = lazy(() => import("../pages/dashboard/contracts/templateEditor/create"));


const MaintenanceRouteWithSidebar = ({ component: Component, ...rest }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <div>
            <div className="mainLayout-component">
              <MaintenanceLayout>
                <SideMenu toggle={toggle} setToggle={setToggle} />
                <div
                  id="main"
                  className={toggle ? "open-mleft" : "close-mleft"}
                >
                  <div>
                    <Breadcrumb toggle={toggle} setToggle={setToggle} />
                  </div>
                  <Suspense fallback={null}>
                    <TypeSelection></TypeSelection>
                  </Suspense>
                  <Suspense fallback={null}>
                    <Component {...props} />
                  </Suspense>
                </div>
              </MaintenanceLayout>
            </div>
          </div>
        </>
      )}
    />
  );
};


const Private = (
      <Switch>
        {/* List all public routes here */}
        <Route exact path="/dashboard" component={DashboardPage} />
        <PrivateRouteWithoutSidebar
          exact
          path={DASHBOARD.INIT}
          component={HomeDashboard}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.INSTALLER_LIST}
          component={Installer}
          layout={InstallerLayout}
          permissions= {['view-installer-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.INSTALLER_EDIT}
          component={InstallerEdit}
          layout={InstallerLayout}
          permissions= {['edit-installer']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.INSTALLER_PRODUCT}
          component={InstallerList}
          layout={InstallerLayout}
          permissions= {['view-installer-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.INSTALLER_APPROVAL_SYSTEM}
          component={ApprovalSystem}
          layout={InstallerLayout}
          permissions= {['can-approve-installer-profile']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.INSTALLER_FILE_LIST}
          component={InstallerFileList}
          layout={InstallerLayout}
          permissions= {['can-view-installer-files']}
          redirect = {{
            status: true,
            reloadAllowed: true,
            component: DASHBOARD.INSTALLER_APPROVAL_SYSTEM
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.INSTALLER_HPWH_SERVICE}
          component={HpwhServiceArea}
          layout={InstallerLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.INSTALLER_SERVICE}
          component={ServiceArea}
          layout={InstallerLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.INSTALLER_TEMPLATE}
          component={TemplateMapping}
          layout={InstallerLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.INSTALLER_LOG_SYSTEM}
          component={HistoryLogs}
          layout={InstallerLayout}
          permissions= {['view-installer-logs']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_MODEL}
          component={CategoryModel}
          layout={InstallerLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_MAKER}
          component={MakerMapping}
          layout={InstallerLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_MAPPING}
          component={ProductMapping}
          layout={InstallerLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_EQUIPMENT}
          component={EquipmentUI}
          layout={InstallerLayout}
          permissions= {['can-add-new-product']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_EQUIPMENT_EDIT}
          component={EquipmentUIEditSolar}
          layout={InstallerLayout}
          permissions= {['can-update-product']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_JSON}
          component={JSONEditor}
          layout={ProductLayoout}
          permissions= {['can-update-product', 'can-view-product-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_ADD_PRODUCT}
          component={AddProduct}
          layout={ProductLayoout}
          permissions= {['can-add-new-product']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_EDITOR}
          component={ProductEditor}
          layout={ProductLayoout}
          permissions= {['can-view-product-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_MANUFACTURER_LIST}
          component={ManufacturerList}
          layout={ProductLayoout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_MANUFACTURER_CREATE}
          component={ManufacturerCreate}
          layout={ProductLayoout}
          permissions= {['can-view-product-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PRODUCTS_MANUFACTURER_EDIT}
          component={ManufacturerEdit}
          layout={ProductLayoout}
          permissions= {['can-view-product-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_LD_HISTORY}
          component={LDHistory}
          layout={ToolsLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_PROXIMITY}
          component={ProximityZips}
          layout={ToolsLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_TRUST_VAULT}
          component={TrustValueVault}
          layout={ToolsLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_PRODUCT_CONFIG}
          component={ProductConfigurator}
          layout={ToolsLayout}
          permissions= {['view-products']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_PARTNER_UTILITY}
          component={PartnerUtilityApiConfigurator}
          layout={ToolsLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PROJECT_LIST}
          component={ProjectList}
          layout={ToolsLayout}
          permissions= {['can-view-project-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PROJECT_LIST_NEW}
          component={ProjectListNew}
          layout={ToolsLayout}
          permissions= {['can-view-project-list-new']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.CONTRACT_TEMPLATE_LIST}
          component={TemplateList}
          layout={ToolsLayout}
          permissions= {['can-view-docusign-templates']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.CONTRACT_TEMPLATE_EDITOR}
          component={TemplateEditor}
          layout={ToolsLayout}
          permissions= {['can-edit-docusign-template']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
         <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.CONTRACT_CREATE_TEMPLATE_EDITOR}
          component={Createtemplate}
          layout={ToolsLayout}
          permissions= {['can-edit-docusign-template']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PROJECT_FILES_LIST}
          component={ProjectFilesList}
          layout={ToolsLayout}
          permissions= {['can-view-project-files']}
          redirect = {{
            status: true,
            reloadAllowed: true,
            component: DASHBOARD.PROJECT_LIST
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.PROJECT_HOME}
          component={ProjectDetail}
          layout={ToolsLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            component: '/dashboard'
          }}
          showBreadcrumb = {true}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_ERROR_LIST}
          component={ErrorLog}
          layout={ToolsLayout}
          permissions= {['can-view-error-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_FILE_UPLOADER}
          component={FileUploader}
          layout={ToolsLayout}
          permissions= {['can-upload-files']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_ERROR_SUMMARY}
          component={ErrorSummary}
          layout={ToolsLayout}
          permissions= {['can-view-error-summary']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_CHANNEL_MAPPING}
          component={ChannelPartnerMapping}
          layout={ToolsLayout}
          permissions= {['view-channel-partner-config']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
              <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.TOOLS_CHANNEL_EDIT}
          component={EditChannelPartnerMapping}
          layout={ToolsLayout}
          permissions= {['view-channel-partner-config']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.USERS_LIST}
          component={UserList}
          layout={UserLayout}
          permissions= {['view-system-users']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.USERS_ROLES}
          component={UserList}
          layout={UserLayout}
          permissions= {['view-roles']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.USERS_HISTORY_LOGS}
          component={UserHistoryLogs}
          layout={UserLayout}
          permissions= {['view-user-history-logs']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <MaintenanceRouteWithSidebar
          exact
          path={DASHBOARD.DOWNTIME_HOME}
          component={Maintenance}
          permissions= {['view-downtime-notification']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <MaintenanceRouteWithSidebar
          exact
          path={DASHBOARD.DOWNTIME_CONFIG}
          component={DowntimeConfigurator}
          permissions= {['edit-downtime-notification']}
          redirect = {{
            status: true,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <MaintenanceRouteWithSidebar
          exact
          path={DASHBOARD.DOWNTIME_REVIEW}
          component={DowntimeReview}
          permissions= {['edit-downtime-notification']}
          redirect = {{
            status: true,
            reloadAllowed: false,
            component: DASHBOARD.DOWNTIME_CONFIG
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_STAFF}
          component={StaffConfigurator}
          layout={SchedulerLayout}
          permissions= {['can-view-staff-list']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_GLOABAL_SETTINGS}
          component={GlobalSettings}
          layout={SchedulerLayout}
          permissions= {['can-view-event-types']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_BULK_EDIT}
          component={StaffBulkEdit}
          layout={SchedulerLayout}
          permissions= {['can-view-staff-bulk-edit']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_BULK_EVENT}
          component={EditEvent}
          layout={SchedulerLayout}
          permissions= {['can-update-staff-bulk-edit']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_BULK_CHANNEL_PARTNER}
          component={EditChannerPartner}
          layout={SchedulerLayout}
          permissions= {['can-update-staff-bulk-edit']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_BULK_REGION}
          component={EditRegion}
          layout={SchedulerLayout}
          permissions= {['can-update-staff-bulk-edit']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_ANALYSIS}
          component={Analysis}
          layout={SchedulerLayout}
          permissions= {[]}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_USER_CONFIGURATION_ADD}
          component={UserConfigurator}
          layout={SchedulerLayout}
          permissions= {['can-save-staff-profile']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_USER_CONFIGURATION_VIEW}
          component={UserConfigurator}
          layout={SchedulerLayout}
          permissions= {['can-view-staff-profile']}
          redirect = {{
            status: false,
            reloadAllowed: true,
            component: '/dashboard'
          }}
        />
        <PrivateRouteWithSidebar
          exact
          path={DASHBOARD.SCHEDULER_USER_CONFIGURATION_EDIT}
          component={UserConfigurator}
          layout={SchedulerLayout}
          permissions= {['can-perform-delete-restore']}
          redirect = {{
            status: true,
            reloadAllowed: true,
            component: DASHBOARD.SCHEDULER_STAFF
          }}
        />

        {/* List all private/auth routes here */}
        {/* <PrivateRoute path={INSTALLER_PRODUCT}> <InstallerProductsView/></PrivateRoute>
       <PrivateRoute path={INSTALLER_LIST}><InstallerListView /></PrivateRoute>
       <PrivateRoute path={ROOT} exact><Home/></PrivateRoute> */}
        {/* Do not hesitate to play around by moving some routes from public to private and vice-versa */}
        {/* <PrivateRoute path={DASHBOARD}>
         <Dashboard />
       </PrivateRoute> */}

        {/* List a generic 404-Not Found route here */}
        <Route path="*">
          <DashboardPage />
        </Route>
      </Switch>
)

const Public = (
  <Switch>
    <Route exact path='/' component={Login} />
    <Route path="*" render={() => localStorage.userData ? <DashboardPage /> : <Login />}  >
    </Route>
  </Switch>
)

const RouterConfig = () => {
  return useAuth()? Private : Public

};

export default RouterConfig;

