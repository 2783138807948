import React,{ Component } from 'react';

const ToolContext = React.createContext();

class ToolProvider extends Component {
    state = {
        productConfigurator : {},
        projectList : {
            selectedProjectId: '',
            projectList: {}
        }
        
    }
    setProjectListData = (data) => {
        this.setState({ projectList: {...this.state.projectList, projectList : data}})
    }
    setProjectSelected = (data) => {
        this.setState({ projectList: {...this.state.projectList, selectedProjectId : data}})
    }
    render(){
        return(
            <ToolContext.Provider value={{
                state: this.state,
                setProjectListData: this.setProjectListData,
                setProjectSelected: this.setProjectSelected
            }}>
                {this.props.children}
            </ToolContext.Provider>
        )
    }
}


export {ToolContext, ToolProvider}