import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { MENU_ITEM, CARDS } from '../../config/roles'
import { CDN_URL } from '../../config'
import { DashboardContext } from '../../services/dashboard/dashboardContext'
import { isActionAllowed, isModuleAllowed } from '../../utils/helper';
import Tooltip from '@mui/material/Tooltip';
// import { useHistory } from "react-router-dom";
// import { showToast } from '../UI/toast';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";
import { capitalize } from '../../utils/helper';
// import { isEmpty } from 'lodash';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function GetMenuIcon({ idx, item, activeRoute, handleClick, activeRouteTitle }) {
  return (
    <Tooltip title={capitalize(item.title)} placement="right" arrow key={idx}>
      <div className={`menu-content ${activeRoute === item.title ? 'nocolor'  : ''}`} onClick={() => handleClick()}>
        {/* <img className="icon" src= {`${CDN_URL}${item.icon}`} alt="sidemenu" ></img> */}
        <i className={`${item.icon} side-icon ${activeRouteTitle === item.title ? 'active' : ''}`} />
      </div>
    </Tooltip>
  );
};

function GetAccordionItem({ item }) {

  return (
    <>
      {
        item.accordion ?
          <Accordion>
            <AccordionSummary
              expandIcon={<i className="icon-chevron-down" />}
              id={item.title}
            >
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                item.menu.forEach((menu, idx) => (
                  menu.accordion ?
                    <GetAccordionItem item={menu} />
                    :
                    <NavLink key={idx} to={menu.path} className="nav-link">{menu.title}</NavLink>
                ))

              }
            </AccordionDetails>
          </Accordion>
          : null
      }
    </>
  )
}


const SideMenu = (props) => {
  const context = useContext(DashboardContext);
  const { pathname } = useLocation();
  const [render, setRender] = useState(true);

  useEffect(() => {
    const path = pathname.split('/')[2];
    context.setActiveRoute(path);
    context.setActiveRouteTitle(path);
    setRender(!render);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);


  const changeScreen = (params = '/', title, available = false, permission) => {
    //Following commented out code is a feature that asked to revert
    // if(available){
    //   // MENU_ITEM[title].map((item, index) => {  
    //     if( permission === '' || isActionAllowed(permission)){
    //   for ( var item of MENU_ITEM[title]) {
    //     if(item.permission === ''){
    //       history.push(params);
    //     }else
    //       if(isActionAllowed(item.permission)){
    //         history.push(item.path);
    //         context.setActiveRoute(title);
    //         break;
    //       } 
    //   }
    //   // history.push(params);
    //   context.setActiveRoute(title);
    // }else{
    //   showToast('ERROR' ,`Permission Denied! You are not allowed to access ${title}`);
    // }
    // }else {
    // showToast('INFO' ,'Coming Soon!')
    // }
    context.setActiveRoute(title);
    props.setToggle(true);
    
  }


  const activeTitle = (menu, title) => {
    props.setToggle(false); 
    context.setActiveRouteTitle(title);
  }


  return (
    <>
      <div id="sidebar-component">
        <div className="fixed-sidebar-icon">
          <div className="menu-content" onClick={() => {
            context.setActiveRoute(context.state.activeRouteTitle);
            props.setToggle(!props.toggle)}
          }>
            {/* <i className={`icon-four_square side-icon `} />  */}
            <img className="icon" src={`${CDN_URL}partner_assets/control-center/dummy/four_square.svg`} alt="sidemenu"
            ></img>
            <hr className="icon mb-0" />
          </div>
          {
            CARDS.map((item, index) => (
              item.type !== 'link' && (item.available && isModuleAllowed(item.roles)) &&
              <GetMenuIcon key={index} idx={index} item={item} activeRoute={context.state.activeRoute} activeRouteTitle={context.state.activeRouteTitle}
                handleClick={() => changeScreen(item.redirect, item.title, item.available, item.permissions)}
              />
            ))
          }
        </div>
        <div className={`sidenav ${props.toggle ? 'close' : 'open'}`}>
          {/* <div style={{textAlign: 'right', marginTop: '40px'}}>
          <img src={`${CDN_URL}partner_assets/control-center/sidebar.svg`} alt="sidebar"
        onClick={() => props.setToggle(!props.toggle)}></img>
          </div> */}
          <Nav defaultActiveKey="/home" className="nav-s" style={{ 'marginTop': '20px' }}>
            <label style={{ 'color': '#333333', 'fontSize': '18px', 'fontFamily': 'ActualB', 'textTransform': 'capitalize' }}>{context.state.activeRoute}</label>
            <hr className="divider" />
            {context.state.activeRoute ?
              MENU_ITEM[context.state.activeRoute].map((item, index) => (
                item.accordion ?
                  <GetAccordionItem item={item} key={index} />
                  : isActionAllowed(item.permission) && <NavLink key = {index} to={item.path} onClick={() => activeTitle(item, context.state.activeRoute)} className="nav-link">{item.title}</NavLink>
                  // <span className={`nav-link`} key={index} onClick={() => activeTitle(item, context.state.activeRoute)} >{item.title}</span>
                // !item.accordion ?
                //   item.permission === '' ?
                //    <NavLink key = {index} to={item.path} className="nav-link">{item.title}</NavLink>
                //    : 
                //     isActionAllowed(item.permission) && <NavLink key = {index} to={item.path} className="nav-link">{item.title}</NavLink>
                //  : <GetAccordionItem  item = {item}/>   
              ))
              : null
            }
          </Nav>
        </div>
      </div>
    </>
  )
}


export default SideMenu;
