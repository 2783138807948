import {
    DASHBOARD
} from '../navigation/CONSTANTS';
import {HUBBLE_URL} from '../services/CONSTANTS';

export const ROLES = {
    'superadmin': {
        "installer": {
            enable: true
        },
        "products": {
            enable: true
        },
        "tools": {
            enable: true
        },
        "user": {
            enable: true
        },
        "KPI": {
            enable: true
        },
        "downtime": {
            enable: true
        }
    },
    'installeradmin': {
        "installer": {
            enable: true
        }
    },
    'user': {
        'user': {
            enable: true
        }
    },
    "downtime-config-admin": {
        "downtime": {
            enable: true
        }
    }
}


export const MENU_ITEM = {
    Dashboard: [],
    installers: [{
            title: 'Dashboard',
            path: DASHBOARD.INSTALLER_LIST,
            permission: '',
            accordion : false,
            menu : []
        },
        {
            title: 'Installer List',
            path: DASHBOARD.INSTALLER_PRODUCT,
            permission: 'view-installer-list',
            accordion : false,
            menu : []
        },
        //    { title : 'Installer Service Area', path : DASHBOARD.INSTALLER_SERVICE,permission: ''},
        //    {title : 'HPWH Service Area', path : DASHBOARD.INSTALLER_HPWH_SERVICE,permission: ''},
        //    {title : 'Installer Template Mapping', path : DASHBOARD.INSTALLER_TEMPLATE,permission: '' } ,
        {
            title: 'Installer Dashboard Approval',
            path: DASHBOARD.INSTALLER_APPROVAL_SYSTEM,
            permission: 'can-view-installer-org-list',
            accordion : false,
            menu : []
        },
        {
            title: 'History Logs',
            path: DASHBOARD.INSTALLER_LOG_SYSTEM,
            permission: 'view-installer-logs',
            accordion : false,
            menu : []
        }
    ],
    products: [
        {
            title: "Product Editor",
            path: '/dashboard/products/editor/hpwh',
            permission: 'can-view-product-list',
            accordion : false,
            menu : []
        },
        {
            title: "Manufacturer",
            path: DASHBOARD.PRODUCTS_MANUFACTURER_LIST,
            permission: 'can-view-manufacturer',
            accordion : false,
            menu : []
        },
        {
            title: "Equipment UI",
            path: '/dashboard/products/equipment/solar',
            permission: 'can-add-new-product',
            accordion : false,
            menu : []
        }
        // { title : 'Category Models', path : DASHBOARD.PRODUCTS_MODEL, permission: ''}, 
        // { title : ' Partner product Mapping', path : DASHBOARD.PRODUCTS_MAPPING, permission: ''},
        // {title : 'Partner Maker Mapping', path :DASHBOARD.PRODUCTS_MAKER, permission: ''}
    ],
    tools: [
        // { title : 'LD History', path : DASHBOARD.TOOLS_LD_HISTORY, permission: ''}, 
        // { title : 'Product Editor (JSON Editor)', path : DASHBOARD.TOOLS_EDITOR, permission: ''},
        // {title : 'Proximity Zips', path :DASHBOARD.TOOLS_PROXIMITY, permission: ''},
        // {title : 'Trust Value Vault', path :DASHBOARD.TOOLS_TRUST_VAULT, permission: ''},
        {
            title: 'Product Configurator',
            path: DASHBOARD.TOOLS_PRODUCT_CONFIG,
            permission: 'view-products',
            accordion : false,
            menu : []
        },
        {
            title: "Partner Bulk Edit",
            path: DASHBOARD.TOOLS_PARTNER_UTILITY,
            permission: 'view-channel-partner-config',
            accordion : false,
            menu : []
        },
        { 
            title: 'Channel Partner',
            path: DASHBOARD.TOOLS_CHANNEL_MAPPING,
            permission: 'view-channel-partner-config',
            accordion : false,
            menu : []
        },
        { 
            title: 'Downtime Management',
            path: DASHBOARD.DOWNTIME_HOME,
            permission: 'view-downtime-notification',
            accordion : false,
            menu : []
        },
        { 
            title: 'Error Summary',
            path: DASHBOARD.TOOLS_ERROR_SUMMARY,
            permission: 'can-view-error-summary',
            accordion : false,
            menu : []
        },
        { 
            title: 'Error List',
            path: DASHBOARD.TOOLS_ERROR_LIST,
            permission: 'can-view-error-list',
            accordion : false,
            menu : []
        },
        {
            title: 'File Uploader',
            path: DASHBOARD.TOOLS_FILE_UPLOADER,
            permission: 'can-upload-files',
            accordion : false,
            menu : []
        },
        // {title: "Project Files List", path: DASHBOARD.TOOLS_PROJECT_FILES_LIST,permission: ''}
    ],
    users: [{
            title: 'User List',
            path: DASHBOARD.USERS_LIST,
            permission: "view-system-users",
            accordion : false,
            menu : []
        },
        {
            title: 'Role Configurator',
            path: DASHBOARD.USERS_ROLES,
            permission: "view-roles",
            accordion : false,
            menu : []
        },
        {
            title: 'History Logs',
            path: DASHBOARD.USERS_HISTORY_LOGS,
            permission: "view-user-history-logs",
            accordion : false,
            menu : []
        }

    ],
    'projects': [
        // { title : 'Downtime Configurations', path : DASHBOARD.DOWNTIME_CONFIG,permission: ''} ,
        {
            title: 'Project List',
            path: DASHBOARD.PROJECT_LIST,
            permission: 'can-view-project-list',
            accordion : false,
            menu : []
        },
        {
            title: 'Project List - New',
            path: DASHBOARD.PROJECT_LIST_NEW,
            permission: 'can-view-project-list-new',
            accordion : false,
            menu : []
        }
    ],

    'contracts': [
        {
            title: 'Active Template',
            path: DASHBOARD.CONTRACT_TEMPLATE_LIST,
            permission: 'can-view-docusign-templates',
            accordion : false,
            menu : []
        },
        {
            title: 'Template Editor',
            path: DASHBOARD.CONTRACT_CREATE_TEMPLATE_EDITOR,
            permission: 'can-edit-docusign-template',
            accordion : false,
            menu : []
        }
    ],

    'scheduler': [{
            title: 'Staff Configuration',
            path: DASHBOARD.SCHEDULER_STAFF,
            permission: 'can-view-staff-list',
            accordion : false,
            menu : []
        },
        {
            title: 'Staff Bulk Edit',
            path: '/dashboard/scheduler/staff-bulk/partner',
            permission: 'can-view-staff-bulk-edit',
            accordion : false,
            menu : []
            
        },
        {
            title: 'Global Settings',
            path: '/dashboard/scheduler/settings/display-times',
            permission: 'can-view-event-types',
            accordion : false,
            menu : []
            
        },
        {
            title: 'Analysis',
            path: DASHBOARD.SCHEDULER_ANALYSIS,
            permission: 'view-scheduler-analysis-staff',
            accordion : false,
            menu : []
        }

    ]
}

export const CARDS = [{
        color: 'primary',
        title: 'installers',
        image: `partner_assets/control-center/installer.svg`,
        icon: `icon-installer`,
        redirect: DASHBOARD.INSTALLER_LIST,
        available: true,
        roles: ['installers'],
        permissions: ''
    },
    {
        color: 'secondary',
        title: 'products',
        image: `partner_assets/control-center/products.svg`,
        icon: `icon-product`,
        redirect: DASHBOARD.PRODUCTS_EDITOR,
        available: true,
        roles: ['products'],
        permissions: ''
    },
    {
        color: 'success',
        title: 'tools',
        image: `partner_assets/control-center/tools.svg`,
        icon: `icon-tool`,
        redirect: DASHBOARD.TOOLS_PRODUCT_CONFIG,
        available: true,
        roles: ['tools'],
        permissions: ''
    },
    {
        color: 'Danger',
        title: 'users',
        image: `partner_assets/control-center/user.svg`,
        icon: `icon-user2`,
        redirect: DASHBOARD.USERS_LIST,
        available: true,
        roles: ['users'],  //users
        permissions: ''
    },
    // {
    //     color: 'Warning',
    //     title: 'KPI',
    //     image: `partner_assets/control-center/kpi.svg`,
    //     icon: `partner_assets/control-center/dummy/user.svg`,
    //     redirect: DASHBOARD,
    //     available: false,
    //     roles: ['superadmin', 'downtime-config-admin'],
    //     permissions: ''
    // },
    {
        color: 'info',
        title: 'projects',
        image: `partner_assets/control-center/Project-list-color.svg`,
        icon: `icon-project-list`,
        redirect: DASHBOARD.PROJECT_LIST,
        available: true,
        roles: ['project-list'],
        permissions: 'can-view-project-list'
    },
    {
        color: 'info',
        title: 'projects',
        image: `partner_assets/control-center/hubble_tile.svg`,
        icon: `icon-project-list`,
        redirect: HUBBLE_URL,
        type : "link",
        available: true,
        roles: ['project-list'],
        permissions: 'can-view-project-list'
    },
    {
        color: 'info',
        title: 'scheduler',
        image: `partner_assets/control-center/scheduler.svg`,
        icon: `icon-call_scheduler`,
        redirect: DASHBOARD.SCHEDULER_STAFF,
        available: true,
        roles: ['scheduler'],
        permissions: ''
    },
    {
        color: 'info',
        title: 'contracts',
        image: `partner_assets/control-center/contract.svg`,
        icon: `icon-contract`,
        redirect: DASHBOARD.CONTRACT_TEMPLATE_LIST,
        available: true,
        roles: ['contracts'],
        permissions: ''
    },
    {
        color: 'info',
        title: 'Legacy',
        image: `partner_assets/control-center/legacy-control-center.svg`,
        icon: `icon-call_scheduler`,
        redirect: DASHBOARD.SCHEDULER_STAFF,
        type : "link",
        available: true,
        roles: ['legacy'],
        permissions: 'can-access-legacy-module'
    },
]

export var PERMISSIONS = [];
export var MODULE = [];
export const setPermissions = (permission) => {
    const list = [];
    // eslint-disable-next-line array-callback-return
    permission.map((item, index) => {
        list.push(item.name)
    })
    list.push("can-upload-files");
    PERMISSIONS = list;
    // console.log(PERMISSIONS);
}

export const setModulePermissions = (modules) => {
    MODULE = modules;
}