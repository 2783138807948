/**
 * List all your axios request related to contract module here and export it 
 * to the index file of services folder to use in respective components.
 */
import axiosInstance from '../../../utils/interceptors/AxiosInstance';
import {ENGINE_URL } from '../../CONSTANTS';

export const templateList = async(data) => {
    return axiosInstance.post(ENGINE_URL + `docusign/get-docusign-templates-list`,data); 
}
export const DeletetemplateList = async(data) => {
    return axiosInstance.post(ENGINE_URL + `docusign/delete-docusign-template`,data); 
}
export const getdocumentsdeta = async(data) => {
    return axiosInstance.post(ENGINE_URL + `docusign/get-docusign-template-content`,data); 
}
export const UPDATEeditor = async(data) => {
    return axiosInstance.post(ENGINE_URL + `docusign/create-template`,data); 
}